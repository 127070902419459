import React from "react";
import Header from "../components/Header";
import { connect, isInWeb3Browser, onAccountChange, onChainSwitch, toEIP55 } from "../api/dapp.js";
import { CHAINS } from "../config/chains.js";
import { Alert, Button, Card, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { uploadFile } from "../api/rest.js";
import { Contract } from "ethers";
import { FACTORY_ABI } from "../abi/FairLaunchLimitBlockV3Factory.abi.js";

import { ethers } from "ethers";
import copy from 'copy-to-clipboard';
import Footer from "../components/footer/Footer.js";

class CreateTokenDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            chain: null,

            salt: '0',
            name: '',
            symbol: '',
            block: null,
            projectOwner: '',
            softCap: '',
            totalSupply: '',
            meta: [],
            uniswapV3Factory: null,
            uniswapV3PositionManager: null,

            image: null,
            // image: 'https://assets.rocket.meme/assets/2024072910/0c065da4-67bc-41cd-a97b-61c7518caa44.jpeg',
            description: '',

            x: '',
            discord: '',
            youtube: '',
            telegram: '',
            gitbook: '',
            website: '',

            contractAddress: '',
            hours: 24,

            address: null,
            step: 0,
            steps: ['Basic Info', 'Tokenomics', 'Media', 'Launch', 'Submit'],

            showSuccessInfo: false,

            txHash: null,
            txWaiting: true,
            txResult: false,

            price: null,

            newblocks: 0,
        };
    }

    componentDidMount() {
        this.init();
    }

    init = async () => {

        if (!isInWeb3Browser()) {
            alert('Please install MetaMask.');
            return;
        }

        try {
            const { chainId, address } = await connect()

            if (CHAINS[chainId] === undefined) {
                // not support
                alert('Chain not supported:' + chainId)
                return;
            }

            console.log('chainId:', chainId)
            this.setState({
                address: toEIP55(address),
                projectOwner: toEIP55(address),
                chain: CHAINS[chainId],
                uniswapV3Factory: CHAINS[chainId].uniswapV3.factory,
                uniswapV3PositionManager: CHAINS[chainId].uniswapV3.positionManager,
            });

            onAccountChange({
                success: this._onAccountChange
            })

            onChainSwitch({
                success: this._onChainSwitch
            })

        } catch (error) {
            console.error(error)
        }
    }

    _onAccountChange = async (accounts) => {
        this.setState({
            address: toEIP55(accounts[0])
        });
    }

    _onChainSwitch = async (chainId) => {
        if (CHAINS[chainId] === undefined) {
            // not support
            alert('Chain not supported:' + chainId)
            return;
        }
        this.init();
    }

    _setTab = (step) => {
        this.setState({
            step: step
        });
    }
    _toBlockNumbers = (hours) => {
        if (this.state.chain) {
            return hours * 3600 / this.state.chain.blockSeconds;
        }
        return 0;
    }

    _checkBaseInfo = async () => {
        // is metamask connected?
        if (!this.state.address) {
            alert('Please connect MetaMask.');
            return;
        }
        // is chain supported? 
        if (!this.state.chain) {
            alert('Chain not supported.');
            return;
        }
        // is name valid?
        if (!this.state.name || !(/^[a-zA-Z0-9\s]+$/.test(this.state.name) && this.state.name.length <= 16)) {
            alert('Name is invalid.');
            return;
        }
        // is symbol valid?
        if (!this.state.symbol || !(/^[a-zA-Z0-9]+$/.test(this.state.symbol) && this.state.symbol.length <= 10)) {
            alert('Symbol is invalid.');
            return;
        }
        // is totalSupply valid?
        if (!this.state.totalSupply || this.state.totalSupply <= 0 || (this.state.totalSupply + '').length >= 18 || !/^\d+$/.test(this.state.totalSupply)) {
            alert('Total Supply is invalid.');
            return;
        }
        // TODO is logo valid?
        // is logo valid?
        if (!this.state.image) {
            alert('Logo is required.');
            return;
        }
        // logo regex check
        if (!/https:\/\/assets.rocket.meme\/[a-zA-Z0-9\\.]/.test(this.state.image)) {
            alert('Logo is invalid.');
            return;
        }
        // is description valid?
        if (!this.state.description || this.state.description.length > 1024) {
            alert('Description is invalid.');
            return;
        }

        this._setTab(1);
    }

    _checkMediaInfo = async () => {
        // is website valid?
        if (this.state.website && !/^https?:\/\/[a-zA-Z0-9\\.\\-]+\.[a-zA-Z]{2,5}(\/\S*)?$/.test(this.state.website)) {
            alert('Website is invalid.');
            return;
        }
        // is telegram valid?
        if (this.state.telegram && !/^https?:\/\/t\.me\/[a-zA-Z0-9_]{5,32}$/.test(this.state.telegram)) {
            alert('Telegram is invalid.');
            return;
        }
        // is gitbook valid?
        if (this.state.gitbook && !/^https?:\/\/[a-zA-Z0-9\\.\\-]+\.[a-zA-Z]{2,5}(\/\S*)?$/.test(this.state.gitbook)) {
            alert('Gitbook is invalid.');
            return;
        }
        // is x valid? x.com || twitter.com
        if (this.state.x && !/^https?:\/\/x\.com\/[a-zA-Z0-9_\\-]+?$/.test(this.state.x) 
            && !/^https?:\/\/twitter\.com\/[a-zA-Z0-9_\\-]+?$/.test(this.state.x)
        ) {
            alert('X is invalid.');
            return;
        }
        // is discord valid?
        if (this.state.discord && !/^https?:\/\/[a-zA-Z0-9\\.\\-]+\.[a-zA-Z]{2,5}(\/\S*)?$/.test(this.state.discord)) {
            alert('Discord is invalid.');
            return;
        }
        // is youtube valid?
        if (this.state.youtube && !/^https?:\/\/[a-zA-Z0-9\\.\\-]+\.[a-zA-Z]{2,5}(\/\S*)?$/.test(this.state.youtube)) {
            alert('Youtube is invalid.');
            return;
        }
        this._setTab(2);
    }

    _checkTokenomicsInfo = async () => {
        // is project owner valid?
        // is start trading valid?
        // is max lp limit valid?
        if (!this.state.projectOwner || !/^0x[a-zA-Z0-9]{40}$/.test(this.state.projectOwner)) {
            alert('Project owner is invalid.');
            return;
        }

        if (!this.state.hours || (this.state.hours !== 24 && this.state.hours !== 48 && this.state.hours !== 72 && (this.state.hours < 1 || this.state.hours > 1440 || !/^\d+$/.test(this.state.hours)))) {
            alert('Start trading is invalid.');
            return;
        }

        if (!this.state.softCap || (this.state.softCap < 0.01 || this.state.softCap > 10000000000)) {
            alert('LP Maximum Limit is invalid.');
            return;
        }
        this._setTab(4);
        this._updateContractAddress();
    }

    _checkLaunchInfo = async () => {
        // is salt valid?
        // is uniswap v3 factory valid?
        // is uniswap v3 position manager valid?
    }

    _buildMeta = () => {
        const meta = []
        // description
        if (this.state.description) {
            meta.push({
                'trait_type': 'description',
                'value': this.state.description
            });
        }
        if (this.state.website) {
            meta.push({
                'trait_type': 'website',
                'value': this.state.website
            });
        }
        if (this.state.telegram) {
            meta.push({
                'trait_type': 'telegram',
                'value': this.state.telegram
            });
        }
        if (this.state.gitbook) {
            meta.push({
                'trait_type': 'gitbook',
                'value': this.state.gitbook
            });
        }
        if (this.state.x) {
            meta.push({
                'trait_type': 'x',
                'value': this.state.x
            });
        }
        if (this.state.discord) {
            meta.push({
                'trait_type': 'discord',
                'value': this.state.discord
            });
        }
        if (this.state.youtube) {
            meta.push({
                'trait_type': 'youtube',
                'value': this.state.youtube
            });
        }

        // image
        meta.push({
            'trait_type': 'image',
            'value': this.state.image
        });

        const metaStr = 'data:application/json;base64,' + btoa(JSON.stringify(meta));
        console.log('metaStr:', metaStr);
        return metaStr;
    }

    _updateContractAddress = async () => {
        const metaStr = this._buildMeta();
        // according to the parameters, generate a contract address
        const options = await connect();
        const factoryContract = new Contract(this.state.chain.fairLaunchFactory, FACTORY_ABI, options.provider);

        const params = [
            ethers.parseUnits(this.state.totalSupply, 18),
            this.state.uniswapV3PositionManager,
            this.state.uniswapV3Factory,
            this.state.name,
            this.state.symbol,
            metaStr,
            this.state.hours * 3600 / this.state.chain.blockSeconds,
            ethers.parseUnits(this.state.softCap, 18),
            600,
            '0x0000000000000000000000000000000000000000'
        ]

        console.log('params:', params);
        console.log('fairLaunchFactory:', this.state.chain.fairLaunchFactory);

        const contractAddress = await factoryContract.getFairLaunchLimitBlockV3Address(
            // salt
            this.state.salt,
            // project owner
            this.state.projectOwner,
            // pool fee
            this.state.chain.feePool,
            // params
            params, {
            value: this.state.price
        }
        );

        console.log('contractAddress:', contractAddress);
        this.setState({
            contractAddress: contractAddress
        });

        // get price
        const price = await factoryContract.price()
        this.setState({
            price: price
        });

    }

    _checkSubmit = async () => {

        const metaStr = this._buildMeta();

        const options = await connect();
        const signer = await options.provider.getSigner();

        const factoryContract = new Contract(this.state.chain.fairLaunchFactory, FACTORY_ABI, signer);
        const params = [
            ethers.parseUnits(this.state.totalSupply, 18),
            this.state.uniswapV3PositionManager,
            this.state.uniswapV3Factory,
            this.state.name,
            this.state.symbol,
            metaStr,
            this.state.hours * 3600 / this.state.chain.blockSeconds,
            ethers.parseUnits(this.state.softCap, 18),
            600,
            '0x0000000000000000000000000000000000000000'
        ]

        try {

            console.log('submit params:', params);

            const tx = await factoryContract.deployFairLaunchLimitBlockV3Contract(
                // salt
                this.state.salt,
                // project owner
                this.state.projectOwner,
                // pool fee
                this.state.chain.feePool,
                // params
                params,
                {
                    value: this.state.price
                }
            );

            this.setState({
                txHash: tx.hash,
                showSuccessInfo: true,
                txWaiting: true
            });
            console.log('tx:', tx);
            await tx.wait();

            const currentBlockNumber = await options.provider.getBlockNumber();
            const newblocks = this.state.hours * 3600 / this.state.chain.blockSeconds + currentBlockNumber;
            this.setState({
                txWaiting: false,
                txResult: true,
                newblocks: newblocks
            });
        } catch (e) {
            console.error(e);
            this.setState({
                txWaiting: false,
                txResult: false
            });
            alert('Transaction failed:' + (e.reason || e.message || e));
        }
    }

    _doUpload = async (e) => {
        // upload image
        const file = e.target.files[0];
        if (!file) {
            return;
        }
        // less 1mb
        if (file.size > 1024 * 1024) {
            alert('The file size is too large.');
            return;
        }
        // check file type
        if (!/image\/(png|jpg|jpeg|gif|svg)/.test(file.type)) {
            alert('The file type is not supported.');
            return;
        }

        // upload
        const data = await uploadFile('/upload', 'file', file);
        console.log(data);
        const image = 'https://assets.rocket.meme' + data;

        this.setState({
            image: image
        });
    }

    render() {
        const { step } = this.state;
        const panelStyle = {

        }
        const infoLineStyle = {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            lineHeight: "2rem",
            gap: "1rem",
        }
        return <div style={{

        }}>
            <Header chain={this.state.chain} onConnectClick={(e) => {
                this.init();
            }} address={this.state.address} />
            <div style={{
                maxWidth: "800px",
                margin: "1rem auto",
                width: "100%",
                padding: "1rem",
            }}>
                <div style={{}}>
                    <div style={{
                        textAlign: "center",
                        marginBottom: "1rem",
                    }}>
                        <a href="https://rocket.meme" style={{
                            fontSize: "1.5rem",
                            color: "#fff",

                        }} rel="https://create.rocket.meme" >
                            [back]
                        </a>
                    </div>
                    {step === 0 ? <div style={panelStyle}>
                        <Alert variant="info">
                            <Alert.Heading>Issue a fair launch token</Alert.Heading>
                            <p>By issuing a token, you can get the income generated by permanently locking up LP. If you are committed to promoting token trading, you will be able to get more commission income.</p>
                            <p>As a token token owner, you can obtain 91% of the permanently locked LP fee income, and the remaining 9% will be paid to the Rocket team as the Rocket protocol fee. As long as the token transaction continues, you can continue to obtain this income forever.</p>
                        </Alert>
                        <Card border="warning" >
                            <Card.Header>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}>
                                    <div>
                                        Create Token
                                    </div>
                                    <div>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}>
                                            <div>
                                                <Button variant="warning" type="submit" onClick={(e) => {
                                                    this._checkBaseInfo();
                                                }}>
                                                    Next
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                {/* chain name display*/}
                                <Form.Group className="mb-3" controlId="formChain" as={Row} >
                                    <Form.Label column sm={2}>Chain</Form.Label>
                                    <Col sm={10} style={{
                                        alignContent: "center",
                                    }}>
                                        <div>
                                            {this.state.chain ? this.state.chain.name : 'Not Connected'}
                                        </div>
                                    </Col>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formName" as={Row} >
                                    <Form.Label column sm={2}>* Name</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text" placeholder="TEST ETH"
                                            onChange={(e) => {
                                                this.setState({ name: e.target.value });
                                            }
                                            }
                                            // this.state.name.length > 16 and alpha beta and space
                                            isInvalid={this.state.name && (/[^a-zA-Z0-9\s]/.test(this.state.name) || this.state.name.length > 16)}
                                            isValid={this.state.name && /^[a-zA-Z0-9\s]+$/.test(this.state.name) && this.state.name.length <= 16}
                                            maxLength={16}
                                            value={this.state.name}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formSymbol" as={Row} >
                                    <Form.Label column sm={2}>* Symbol</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text" placeholder="ETH"
                                            onChange={(e) => {
                                                this.setState({ symbol: e.target.value });
                                            }}
                                            // this.state.symbol.length > 6 and alpha beta and space
                                            isInvalid={this.state.symbol && (/[^a-zA-Z0-9\s]/.test(this.state.symbol) || this.state.symbol.length > 10)}
                                            isValid={this.state.symbol && /^[a-zA-Z0-9]+$/.test(this.state.symbol) && this.state.symbol.length <= 10}
                                            maxLength={10}
                                            value={this.state.symbol}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formLogo" as={Row} >
                                    <Form.Label column sm={2}>* Logo</Form.Label>
                                    <Col sm={10}>
                                        <div style={{
                                            width: "100px",
                                            height: "100px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            border: "1px solid #495057",
                                            borderRadius: "5px",
                                        }} onClick={(e) => {
                                            document.getElementById('fileInput').click();
                                        }}>
                                            {!this.state.image ? <img src={require('../assets/icon/plus.png')} alt="logo" style={{
                                                width: "30px",
                                                height: "30px",
                                            }} /> : <img src={this.state.image} alt="logo" style={{
                                                width: "100px",
                                                height: "100px",
                                            }} />}
                                        </div>
                                        <Form.Text id="formLogoHelpBlock" muted>
                                            Recommended size: 300x300 px, supports jpg, png, gif, max formats, Max size: 1MB
                                        </Form.Text>
                                    </Col>
                                </Form.Group>
                                <input type="file" id="fileInput" style={{ display: 'none' }} onChange={(e) => {
                                    this._doUpload(e)
                                }}></input>

                                <Form.Group className="mb-3" controlId="formTotalSupply" as={Row} >
                                    <Form.Label column sm={2}>* Total Supply</Form.Label>
                                    <Col sm={10}>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                placeholder="100000000"
                                                type="number"
                                                aria-label="Total Supply"
                                                aria-describedby="basic-addon2"
                                                onChange={(e) => {
                                                    this.setState({ totalSupply: e.target.value });
                                                }
                                                }
                                                maxLength={18}
                                                minLength={1}
                                                isValid={this.state.totalSupply > 0 && (this.state.totalSupply + '').length < 18}
                                                isInvalid={this.state.totalSupply && (this.state.totalSupply <= 0 || (this.state.totalSupply + '').length >= 18)}
                                                value={this.state.totalSupply}

                                            />
                                            <InputGroup.Text id="basic-addon2"> * 10 ** 18</InputGroup.Text>
                                        </InputGroup>
                                        <Form.Text id="formLogoHelpBlock" muted>
                                            {/* format to ###,###,### */}
                                            {
                                                this.state.totalSupply && this.state.totalSupply.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                            }
                                            {
                                                this.state.symbol ? ' ' + this.state.symbol : ''
                                            }
                                        </Form.Text>
                                    </Col>
                                </Form.Group>

                                {/* textarea of description */}
                                <Form.Group className="mb-3" controlId="formDescription" as={Row} >
                                    <Form.Label column sm={2}>* Description</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control as="textarea" placeholder="No more than 200 characters, this description will be displayed on the blockchain. " rows={3}
                                            maxLength={1024}
                                            isInvalid={this.state.description && this.state.description.length > 1024}
                                            onChange={
                                                (e) => {
                                                    this.setState({ description: e.target.value });
                                                }
                                            }
                                            value={this.state.description}
                                        />
                                    </Col>
                                </Form.Group>

                            </Card.Body></Card>
                    </div> : null}
                    {step === 2 ? <div>
                        {/* When start trading? */}
                        <Card border="warning">
                            <Card.Header>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}>
                                    <Button variant="secondary" type="submit" onClick={(e) => {
                                        this._setTab(1);
                                    }}>
                                        Previous
                                    </Button>
                                    <Button variant="warning" type="submit" onClick={(e) => {
                                        this._checkTokenomicsInfo();
                                        
                                    }}>
                                        Next
                                    </Button>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                {/* Project Owner */}
                                <Form.Group className="mb-3" controlId="formProjectOwner" as={Row} >
                                    <Form.Label column sm={2}>* Owner</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="email" placeholder="Enter project owner address"
                                            value={this.state.address ? toEIP55(this.state.address) : ''}
                                            onChange={(e) => {
                                                this.setState({ projectOwner: e.target.value });
                                            }
                                            }
                                            // ^0x[a-zA-Z0-9]{40}$
                                            isInvalid={this.state.projectOwner && !/^0x[a-zA-Z0-9]{40}$/.test(this.state.projectOwner)}
                                            isValid={this.state.projectOwner && /^0x[a-zA-Z0-9]{40}$/.test(this.state.projectOwner)}
                                        />
                                        <Form.Text id="formLogoHelpBlock" muted>
                                            Project owner address, which will be used to claim the profit of LP.
                                        </Form.Text>
                                    </Col>
                                </Form.Group>


                                <Form.Group className="mb-3" controlId="formStartTrading" as={Row} >
                                    <Form.Label column sm={2}>* FairMint Ending Block</Form.Label>
                                    <Col sm={10}>
                                        <Alert variant="info">
                                            <p>FairMint Ending Block</p>
                                            {/* <p>After the contract is deployed, a transaction can be initiated to add liquidity to the pool after a specific block number by sending 0.0005 ETH directly to the contract.</p> */}
                                            <p>Different blockchains have different block generation times. The program will try to set the startup block number according to the estimated time, but there may still be deviations. Please use the block number as the standard for the actual startup time.</p>
                                        </Alert>

                                        {/* radio 12 hours / 24 hours / 48 hours / custom hours */}
                                        <Form.Check type="radio"
                                            label={'24 hours' + (this.state.chain ? ' (about ' + this._toBlockNumbers(24) + ' blocks on ' + this.state.chain.name + ')' : '')}
                                            name="startTrading"
                                            id="startTrading24"
                                            checked={this.state.hours === 24}
                                            onChange={
                                                (e) => {
                                                    this.setState({ hours: 24 });
                                                }
                                            }

                                        />
                                        <Form.Check type="radio" label={
                                            '48 hours' + (this.state.chain ? ' (about ' + this._toBlockNumbers(48) + ' blocks on ' + this.state.chain.name + ')' : '')
                                        } name="startTrading" id="startTrading48"
                                            checked={this.state.hours === 48}
                                            onChange={
                                                (e) => {
                                                    this.setState({ hours: 48 });
                                                }
                                            }
                                        />
                                        <Form.Check type="radio" label={
                                            '72 hours' + (this.state.chain ? ' (about ' + this._toBlockNumbers(72) + ' blocks on ' + this.state.chain.name + ')' : '')
                                        } name="startTrading" id="startTrading72"
                                            checked={this.state.hours === 72}
                                            onChange={
                                                (e) => {
                                                    this.setState({ hours: 72 });
                                                }
                                            }
                                        />
                                        <div>
                                            <Row style={{ alignItems: "baseline" }}>
                                                <Col sm={2}>
                                                    <Form.Check type="radio" label="Custom" name="startTrading" id="startTradingCustom"
                                                        checked={this.state.hours !== 24 && this.state.hours !== 48 && this.state.hours !== 72}
                                                        onChange={
                                                            (e) => {
                                                                this.setState({ hours: null });
                                                            }
                                                        }
                                                    />
                                                </Col>
                                                {/* custon hours input */}
                                                <Col sm={10}>
                                                    <InputGroup className="mb-3">
                                                        <Form.Control type="number" placeholder="36" onChange={(e) => {
                                                            this.setState({ hours: e.target.value });
                                                        }}
                                                            disabled={this.state.hours === 24 || this.state.hours === 48 || this.state.hours === 72}
                                                            // must >= 1
                                                            // must integer
                                                            // must <= 1440
                                                            isValid={this.state.hours && this.state.hours >= 1 && this.state.hours <= 1440 && /^\d+$/.test(this.state.hours)}
                                                            isInvalid={this.state.hours && (this.state.hours < 1 || this.state.hours > 1440 || !/^\d+$/.test(this.state.hours))}
                                                            value={this.state.hours}

                                                        />
                                                        <InputGroup.Text id="basic-addon2">
                                                            hours later
                                                        </InputGroup.Text>
                                                    </InputGroup>
                                                    <Form.Text id="formLogoHelpBlock" muted>
                                                        <p>
                                                            {
                                                                this.state.chain && this.state.hours ? '(About ' + this._toBlockNumbers(this.state.hours) + ' blocks on ' + this.state.chain.name + ')' : ''
                                                            }
                                                        </p>
                                                        (The hours must be an integer between 1 and 1440)

                                                    </Form.Text>

                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Form.Group>

                                {/* Maximum LP Limit */}
                                <Form.Group className="mb-3" controlId="formMaxLPLimit" as={Row} >
                                    <Form.Label column sm={2}>* LP Maximum Limit</Form.Label>
                                    <Col sm={10}>
                                        <Row style={{ alignItems: 'baseline' }}>
                                            <Col sm={3}>
                                                50% {
                                                    this.state.symbol ? ' of ' + this.state.symbol : ' Tokens'
                                                }
                                            </Col>
                                            <Col sm={1}>
                                                /
                                            </Col>
                                            <Col sm={5}>
                                                <InputGroup className="mb-3">
                                                    <Form.Control type="number" placeholder="10"
                                                        onChange={(e) => {
                                                            this.setState({ softCap: e.target.value });
                                                        }
                                                        }
                                                        // must >= 1
                                                        // must integer
                                                        // must <= 1000000000000000000
                                                        isValid={this.state.softCap && this.state.softCap >= 0.01 && this.state.softCap <= 10000000000}
                                                        isInvalid={this.state.softCap && (this.state.softCap < 0.01 || this.state.softCap > 10000000000)}
                                                        value={this.state.softCap}
                                                    />
                                                    <InputGroup.Text id="basic-addon2">
                                                        {this.state.chain ? this.state.chain.symbol : '-'}
                                                    </InputGroup.Text>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <Form.Text id="formLogoHelpBlock" muted>
                                            Funds raised exceeding the LP maximum limit will be proportionally refunded to all participants.
                                        </Form.Text>
                                    </Col>

                                </Form.Group>
                            </Card.Body>
                        </Card>
                    </div> : null}

                    {step === 1 ? <div>
                        <Card border="warning">
                            <Card.Header>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}>
                                    <Button variant="secondary" type="submit" onClick={(e) => {
                                        this._setTab(0);
                                    }}>
                                        Previous
                                    </Button>
                                    <Button variant="warning" type="submit" onClick={(e) => {
                                        // this._setTab(2);
                                        this._checkMediaInfo();

                                    }}>
                                        Next
                                    </Button>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                {/* Website */}
                                <Form.Group className="mb-3" controlId="formWebsite" as={Row} >
                                    <Form.Label column sm={2} style={{
                                        alignItems: "center",
                                        display: "flex",
                                    }}>
                                        <img src={require('../assets/icon/website.png')} alt="Website" style={{
                                            width: "0.8rem",
                                            height: "0.8rem",
                                            marginRight: "0.5rem",
                                        }} />
                                        Website
                                    </Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text" placeholder="(Optional)"
                                            onChange={(e) => {
                                                this.setState({ website: e.target.value });
                                            }}
                                            isValid={this.state.website && /^https?:\/\/[a-zA-Z0-9\\.\\-]+\.[a-zA-Z]{2,5}(\/\S*)?$/.test(this.state.website)}
                                            isInvalid={this.state.website && !/^https?:\/\/[a-zA-Z0-9\\.\\-]+\.[a-zA-Z]{2,5}(\/\S*)?$/.test(this.state.website)}
                                            value={this.state.website}
                                        />
                                    </Col>
                                </Form.Group>

                                {/* Telegram */}
                                <Form.Group className="mb-3" controlId="formTelegram" as={Row} >
                                    <Form.Label column sm={2} style={{
                                        alignItems: "center",
                                        display: "flex",
                                    }}>
                                        <img src={require('../assets/icon/telegram.png')} alt="Telegram" style={{
                                            width: "0.8rem",
                                            height: "0.8rem",
                                            marginRight: "0.5rem",
                                        }} />
                                        Telegram
                                    </Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text" placeholder="(Optional)"
                                            onChange={(e) => {
                                                this.setState({ telegram: e.target.value });
                                            }}
                                            isValid={this.state.telegram && /^https?:\/\/t\.me\/[a-zA-Z0-9_]{5,32}$/.test(this.state.telegram)}
                                            isInvalid={this.state.telegram && !/^https?:\/\/t\.me\/[a-zA-Z0-9_]{5,32}$/.test(this.state.telegram)}
                                            value={this.state.telegram}
                                        />
                                    </Col>
                                </Form.Group>

                                {/* gitbook */}
                                <Form.Group className="mb-3" controlId="formGitbook" as={Row} >
                                    <Form.Label column sm={2}
                                        style={{
                                            alignItems: "center",
                                            display: "flex",
                                        }}
                                    >
                                        <img src={require('../assets/icon/gitbook.png')} alt="Gitbook" style={{
                                            width: "0.8rem",
                                            height: "0.8rem",
                                            marginRight: "0.5rem",
                                        }} />
                                        Gitbook</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text" placeholder="(Optional)"

                                            onChange={(e) => {
                                                this.setState({ gitbook: e.target.value });
                                            }}
                                            isValid={this.state.gitbook && /^https?:\/\/[a-zA-Z0-9\\.\\-]+\.[a-zA-Z]{2,5}(\/\S*)?$/.test(this.state.gitbook)}
                                            isInvalid={this.state.gitbook && !/^https?:\/\/[a-zA-Z0-9\\.\\-]+\.[a-zA-Z]{2,5}(\/\S*)?$/.test(this.state.gitbook)}
                                            value={this.state.gitbook}
                                        />
                                    </Col>
                                </Form.Group>

                                {/* X */}
                                <Form.Group className="mb-3" controlId="formX" as={Row} >
                                    <Form.Label column sm={2}
                                        style={{
                                            alignItems: "center",
                                            display: "flex",
                                        }}
                                    >
                                        <img src={require('../assets/icon/x.png')} alt="X" style={{
                                            width: "0.8rem",
                                            height: "0.8rem",
                                            marginRight: "0.5rem",
                                        }} />
                                        X</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text" placeholder="(Optional)"
                                            //pattern=  ^https://x.com/[a-zA-Z0-9_]{5,32}$
                                            onChange={(e) => {
                                                this.setState({ x: e.target.value });
                                            }}
                                            // prefix of https://x.com/xxxxx or prefix of https://twitter.com
                                            isValid={this.state.x && (/^https?:\/\/x\.com\/[a-zA-Z0-9_\\-]+?$/.test(this.state.x) || /^https?:\/\/twitter\.com/.test(this.state.x))} 
                                            isInvalid={this.state.x && !/^https?:\/\/x\.com\/[a-zA-Z0-9_\\-]+?$/.test(this.state.x) && !/^https?:\/\/twitter\.com/.test(this.state.x)}
                                            value={this.state.x}
                                        />
                                    </Col>
                                </Form.Group>

                                {/* Discord */}
                                <Form.Group className="mb-3" controlId="formDiscord" as={Row} >
                                    <Form.Label column sm={2} style={{
                                        alignItems: "center",
                                        display: "flex",
                                    }}>
                                        <img src={require('../assets/icon/discord.png')} alt="Discord" style={{
                                            width: "0.8rem",
                                            height: "0.8rem",
                                            marginRight: "0.5rem",
                                        }} />

                                        Discord</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text" placeholder="(Optional)"

                                            onChange={(e) => {
                                                this.setState({ discord: e.target.value });
                                            }}

                                            isValid={this.state.discord && /^https?:\/\/[a-zA-Z0-9\\.\\-]+\.[a-zA-Z]{2,5}(\/\S*)?$/.test(this.state.discord)}
                                            isInvalid={this.state.discord && !/^https?:\/\/[a-zA-Z0-9\\.\\-]+\.[a-zA-Z]{2,5}(\/\S*)?$/.test(this.state.discord)}
                                            value={this.state.discord}
                                        />
                                    </Col>
                                </Form.Group>

                                {/* Youtube */}
                                <Form.Group className="mb-3" controlId="formYoutube" as={Row} >
                                    <Form.Label column sm={2} style={{
                                        alignItems: "center",
                                        display: "flex",
                                    }}>
                                        <img src={require('../assets/icon/youtube.png')} alt="Youtube" style={{
                                            width: "0.8rem",
                                            height: "0.8rem",
                                            marginRight: "0.5rem",
                                        }} />Youtube
                                    </Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text" placeholder="(Optional)"
                                            onChange={(e) => {
                                                this.setState({ youtube: e.target.value });
                                            }}
                                            isValid={this.state.youtube && /^https?:\/\/[a-zA-Z0-9\\.\\-]+\.[a-zA-Z]{2,5}(\/\S*)?$/.test(this.state.youtube)}
                                            isInvalid={this.state.youtube && !/^https?:\/\/[a-zA-Z0-9\\.\\-]+\.[a-zA-Z]{2,5}(\/\S*)?$/.test(this.state.youtube)}
                                            value={this.state.youtube}
                                        />
                                    </Col>
                                </Form.Group>
                            </Card.Body>
                        </Card>
                    </div> : null}
                    {/* Submit */}
                    {step === 3 ? <div>
                        <Card border="warning">
                            <Card.Header>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}>
                                    <Button variant="secondary" type="submit" onClick={(e) => {
                                        this._setTab(2);
                                    }}>
                                        Previous
                                    </Button>
                                    <Button variant="warning" type="submit" onClick={(e) => {
                                        this._setTab(4);
                                    }}>
                                        Confirm
                                    </Button>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Alert variant="info">
                                    <p>Contract address estimated: <b>{this.state.contractAddress ? this.state.contractAddress : "-"}</b></p>
                                    <p>How to deploy a contract with same address on other EVM chain?</p>
                                </Alert>
                                <Form.Group className="mb-3" controlId="formSalt" as={Row} >
                                    <Form.Label column sm={2}>* Salt</Form.Label>
                                    <Col sm={10}>
                                        <InputGroup>
                                            <Form.Control type="number" placeholder="Enter a salt number" value={this.state.salt + ''}
                                                onChange={(e) => {
                                                    this.setState({ salt: e.target.value });
                                                }
                                                }
                                            />
                                            <Button
                                                variant="outline-secondary"
                                                onClick={(e) => {
                                                    this.setState({
                                                        salt: (Math.random() * 1000000000).toFixed(0)
                                                    });
                                                    this._updateContractAddress();
                                                }}
                                            > Random </Button>
                                        </InputGroup>
                                        <Form.Text id="formSaltHelpBlock" muted>
                                            Salt is a randomly given number. By changing this number, you can get a different contract address.
                                            But please note that the same salt can only be deployed once on the same chain.
                                        </Form.Text>
                                    </Col>
                                </Form.Group>
                                {/* Uniswap v3 factory address */}
                                <Form.Group className="mb-3" controlId="formUniswapV3Factory" as={Row} >
                                    <Form.Label column sm={2}>* Uniswap Factory</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text" placeholder="Enter Uniswap V3 Factory address"
                                            value={
                                                this.state.uniswapV3Factory ? this.state.uniswapV3Factory : ''
                                            }
                                            onAbort={(e) => {
                                                this.setState({ uniswapV3Factory: e.target.value });
                                                this._updateContractAddress();
                                            }}
                                        />
                                        <Form.Text id="formSaltHelpBlock" muted>
                                            UniswapV3Factory
                                        </Form.Text>
                                    </Col>
                                </Form.Group>

                                {/* Uniswap v3 position manager address */}
                                <Form.Group className="mb-3" controlId="formUniswapV3PositionManager" as={Row} >
                                    <Form.Label column sm={2}>* Uniswap Position</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text" placeholder="Enter Uniswap V3 Position Manager address" value={
                                            this.state.uniswapV3PositionManager ? this.state.uniswapV3PositionManager : ''
                                        }
                                            onChange={(e) => {
                                                this.setState({ uniswapV3PositionManager: e.target.value });
                                                this._updateContractAddress();
                                            }}
                                        />
                                        <Form.Text id="formSaltHelpBlock" muted>
                                            NonfungiblePositionManager
                                        </Form.Text>
                                    </Col>

                                </Form.Group>

                            </Card.Body>
                        </Card>
                    </div> : null}

                    {/* final confirm */}
                    {step === 4 ? <div>
                        <Card border="warning">
                            <Card.Header>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}>
                                    <Button variant="secondary" type="submit" onClick={(e) => {
                                        this._setTab(2);
                                    }}>
                                        Previous
                                    </Button>
                                    <Button variant="warning" type="submit"
                                        onClick={(e) => {
                                            this._checkSubmit()
                                        }}
                                    >
                                        Issue {
                                            this.state.price > 0 ? ' (Price: ' + ethers.formatEther(this.state.price) + ' ETH)' : ''
                                        }
                                    </Button>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Alert variant="info">
                                    <div style={{
                                        display: "flex",

                                    }}>
                                        <div>
                                            <img src={this.state.image} alt="logo" style={{
                                                width: "100px",
                                                height: "100px",
                                                marginRight: "1rem"
                                            }} />
                                        </div>
                                        <div style={{
                                            width: "100%",
                                        }}>
                                            <div style={infoLineStyle}>
                                                <div>Name </div>
                                                <div><b>{this.state.name}</b></div>
                                            </div>
                                            <div style={infoLineStyle}>
                                                <div>Symbol</div>
                                                <div><b>{this.state.symbol}</b></div>
                                            </div>
                                            <div style={infoLineStyle}>
                                                <div>Total Supply</div>
                                                <div><b>{this.state.totalSupply}</b></div>
                                            </div>
                                            <div style={infoLineStyle}>
                                                <div>Chain </div>
                                                <div><b>{this.state.chain ? this.state.chain.name : ''}</b></div>
                                            </div>
                                            {/* description */}
                                            <div style={infoLineStyle}>
                                                <div>Description </div>
                                                <div><b>{this.state.description}</b></div>
                                            </div>
                                        </div>

                                    </div>
                                </Alert>

                                <Alert variant="secondary">
                                <div style={infoLineStyle}>
                                        <div>Creator: </div>
                                        <div style={{
                                            alignItems: "center",
                                            display: "flex",
                                        }} onClick={(e) => {
                                            if (this.state.chain && this.state.projectOwner) {
                                                const url = this.state.chain.blockExplorer + '/address/' + this.state.projectOwner;
                                                window.open(url, '_blank');
                                            }
                                        }}>
                                            <div>{this.state.projectOwner ?
                                                this.state.projectOwner.substring(0, 8) + '...' + this.state.projectOwner.substring(this.state.uniswapV3PositionManager.length - 6)
                                                : ''}
                                            </div>
                                            <img src={require('../assets/icon/open.png')} alt="external link" style={{
                                                width: "0.8rem",
                                                height: "0.8rem",
                                                marginLeft: "0.5rem"
                                            }} />
                                        </div>
                                    </div>
                                    <div style={infoLineStyle}>
                                        <div>FairMint ending blocks: </div>
                                        <div>{this.state.hours * 3600 / this.state.chain.blockSeconds} Blocks</div>
                                    </div>
                                    
                                    <div style={infoLineStyle}>
                                        <div>LP Max: </div>
                                        <div>{this.state.softCap}
                                            {this.state.chain ? ' ' + this.state.chain.symbol : ''}
                                        </div>
                                    </div>

                                    {/* uniswap v3 factory */}
                                    {/* <div style={infoLineStyle}>
                                        <div>Uniswap V3 Factory: </div>

                                        <div style={{
                                            alignItems: "baseline",
                                            display: "flex",
                                        }} onClick={(e) => {
                                            if (this.state.chain && this.state.uniswapV3Factory) {
                                                const url = this.state.chain.blockExplorer + '/address/' + this.state.uniswapV3Factory;
                                                window.open(url, '_blank');
                                            }
                                        }}>
                                            <div>{this.state.uniswapV3Factory ?
                                                this.state.uniswapV3Factory.substring(0, 8) + '...' + this.state.uniswapV3Factory.substring(this.state.uniswapV3Factory.length - 6)
                                                : ''
                                            }
                                            </div>
                                            <img src={require('../assets/icon/open.png')} alt="external link" style={{
                                                width: "0.8rem",
                                                height: "0.8rem",
                                                marginLeft: "0.5rem"
                                            }} />
                                        </div>
                                    </div> */}
                                    {/* uniswap v3 position manager */}
                                    {/* <div style={infoLineStyle}>
                                        <div>Uniswap V3 Position Manager: </div>
                                        <div style={{
                                            alignItems: "center",
                                            display: "flex",
                                        }} onClick={(e) => {
                                            if (this.state.chain && this.state.uniswapV3PositionManager) {
                                                const url = this.state.chain.blockExplorer + '/address/' + this.state.uniswapV3PositionManager;
                                                window.open(url, '_blank');
                                            }
                                        }}>
                                            <div>{this.state.uniswapV3PositionManager ?
                                                this.state.uniswapV3PositionManager.substring(0, 8) + '...' + this.state.uniswapV3PositionManager.substring(this.state.uniswapV3PositionManager.length - 6)
                                                : ''}
                                            </div>
                                            <img src={require('../assets/icon/open.png')} alt="external link" style={{
                                                width: "0.8rem",
                                                height: "0.8rem",
                                                marginLeft: "0.5rem"
                                            }} />
                                        </div>
                                    </div> */}

                                    {/* all meta items (same as nft's attributes)*/}
                                    {this.state.meta.map((item, index) => {
                                        return <p key={index}>{item.key}: {item.value}</p>
                                    })}
                                </Alert>

                                <Alert variant="secondary">
                                    {/* Media info */}
                                    <div style={infoLineStyle}>
                                        <div>Website: </div>
                                        <div style={{
                                            alignItems: "center",
                                            display: "flex",
                                        }} onClick={(e) => {
                                            if (this.state.website) {
                                                window.open(this.state.website, '_blank');
                                            }
                                        }}>
                                            <div>{this.state.website}</div>
                                            <img src={require('../assets/icon/open.png')} alt="external link" style={{
                                                width: "0.8rem",
                                                height: "0.8rem",
                                                marginLeft: "0.5rem"
                                            }} />
                                        </div>
                                    </div>

                                    <div style={infoLineStyle}>
                                        <div>Telegram: </div>
                                        <div style={{
                                            alignItems: "center",
                                            display: "flex",
                                        }} onClick={(e) => {
                                            if (this.state.telegram) {
                                                window.open(this.state.telegram, '_blank');
                                            }
                                        }}>
                                            <div>{this.state.telegram}</div>
                                            <img src={require('../assets/icon/open.png')} alt="external link" style={{
                                                width: "0.8rem",
                                                height: "0.8rem",
                                                marginLeft: "0.5rem"
                                            }} />
                                        </div>
                                    </div>

                                    <div style={infoLineStyle}>
                                        <div>Gitbook: </div>
                                        <div style={{
                                            alignItems: "center",
                                            display: "flex",
                                        }} onClick={(e) => {
                                            if (this.state.gitbook) {
                                                window.open(this.state.gitbook, '_blank');
                                            }
                                        }}>
                                            <div>{this.state.gitbook}</div>
                                            <img src={require('../assets/icon/open.png')} alt="external link" style={{
                                                width: "0.8rem",
                                                height: "0.8rem",
                                                marginLeft: "0.5rem"
                                            }} />
                                        </div>
                                    </div>

                                    <div style={infoLineStyle}>
                                        <div>X: </div>
                                        <div style={{
                                            alignItems: "center",
                                            display: "flex",
                                        }} onClick={(e) => {
                                            if (this.state.x) {
                                                window.open(this.state.x, '_blank');
                                            }
                                        }}>
                                            <div>{this.state.x}</div>
                                            <img src={require('../assets/icon/open.png')} alt="external link" style={{
                                                width: "0.8rem",
                                                height: "0.8rem",
                                                marginLeft: "0.5rem"
                                            }} />
                                        </div>
                                    </div>

                                    <div style={infoLineStyle}>
                                        <div>Discord: </div>
                                        <div style={{
                                            alignItems: "center",
                                            display: "flex",
                                        }} onClick={(e) => {
                                            if (this.state.discord) {
                                                window.open(this.state.discord, '_blank');
                                            }
                                        }}>
                                            <div>{this.state.discord}</div>
                                            <img src={require('../assets/icon/open.png')} alt="external link" style={{
                                                width: "0.8rem",
                                                height: "0.8rem",
                                                marginLeft: "0.5rem"
                                            }} />
                                        </div>
                                    </div>

                                    <div style={infoLineStyle}>
                                        <div>Youtube: </div>
                                        <div style={{
                                            alignItems: "center",
                                            display: "flex",
                                        }} onClick={(e) => {
                                            if (this.state.youtube) {
                                                window.open(this.state.youtube, '_blank');
                                            }
                                        }}>
                                            <div>{this.state.youtube}</div>
                                            <img src={require('../assets/icon/open.png')} alt="external link" style={{
                                                width: "0.8rem",
                                                height: "0.8rem",
                                                marginLeft: "0.5rem"
                                            }} />
                                        </div>
                                    </div>

                                </Alert>
                            </Card.Body>
                        </Card>
                    </div> : null}
                </div>
            </div>

            {/* show tx result */}
            {/* mask */}
            <Modal show={this.state.showSuccessInfo} onHide={(e) => {
                this.setState({
                    showSuccessInfo: false
                });
            }}>
                {/* <Modal.Header closeButton>
                    <Modal.Title>Transaction Result</Modal.Title>
                </Modal.Header> */}
                <Modal.Body>
                    <div style={{
                        textAlign: "center",
                        marginBottom: "2rem",
                        marginTop: "2rem",
                    }}>
                        {/* waiting */}
                        {this.state.txWaiting ? <img src={require('../assets/icon/waiting.png')} alt="waiting" style={{
                            width: "100px",
                            height: "100px",
                            margin: "0 auto",
                            display: "block",
                        }} /> : this.state.txResult ? <img src={require('../assets/icon/success.png')} alt="success" style={{
                            width: "100px",
                            height: "100px",
                            margin: "0 auto",
                            display: "block",
                        }} /> : <img src={require('../assets/icon/error.png')} alt="error" style={{
                            width: "100px",
                            height: "100px",
                            margin: "0 auto",
                            display: "block",
                        }} />}
                        <div style={{
                            marginTop: '2rem'
                        }}>
                            <Alert.Link href={
                                this.state.chain ? this.state.chain.blockExplorer + '/tx/' + this.state.txHash : "#"
                            } target="_blank">

                                {/* token name and symbol */}
                                {this.state.name} ({this.state.symbol})
                            </Alert.Link>
                        </div>
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        margin: "1rem",
                    }}>
                        <div style={{
                            display: "flex",
                            gap: "0.5rem",
                            alignItems: "center",
                            justifyContent: "start",
                        }}>
                            CA:
                            <img src={this.state.image} alt="logo" style={{
                                width: "0.8rem",
                                height: "0.8rem",
                                marginLeft: "0.5rem",
                            }} />
                            <Alert.Link style={{
                                wordBreak: 'break-all'
                            }} href={
                                this.state.chain ? this.state.chain.blockExplorer + '/address/' + this.state.contractAddress : '#'
                            } target="_blank">{this.state.contractAddress}</Alert.Link>

                            {/* copy image */}
                            <img src={require('../assets/icon/copy.png')} alt="copy" style={{
                                width: "1rem",
                                height: "1rem",
                                marginLeft: "0.5rem",
                                cursor: "pointer",
                            }} onClick={(e) => {
                                copy(this.state.contractAddress);
                                // toast success
                                alert('Copied to clipboard');
                            }} />
                        </div>

                    </div>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "1rem",
                        gap: "1rem",
                    }}>
                        <div>FairMint deadline block: </div>
                        <div>{
                            '#' + this.state.newblocks
                        }</div>
                    </div>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "1rem",
                        gap: "1rem",
                    }}>
                        <div>URL: </div>
                        <Alert.Link
                            href={`https://rocket.meme/dt?address=${this.state.contractAddress}`} target="_blank"
                            style={{
                                wordBreak: 'break-all'
                            }}>https://rocket.meme/dt?address={this.state.contractAddress}</Alert.Link>
                        {/* copy image */}
                        <img src={require('../assets/icon/copy.png')} alt="copy" style={{
                            width: "1rem",
                            height: "1rem",
                            marginLeft: "0.5rem",
                            cursor: "pointer",
                        }} onClick={(e) => {
                            copy(`https://rocket.meme/dt?address=${this.state.contractAddress}`);
                            // toast success
                            alert('Copied to clipboard');
                        }} />
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    {/* Copy parameters */}
                    {/* <Button variant="primary" onClick={(e) => {
                        // TODP Copy Parameters
                    }}>
                        Copy all parameters
                    </Button> */}

                    <Button variant="secondary" onClick={(e) => {
                        this.setState({
                            showSuccessInfo: false
                        });
                        // reload
                        window.location.reload();
                    }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Footer />
        </div>;
    }
}

function CreateToken() {
    return <CreateTokenDetail />;
}

export default CreateToken;