export const CHAINS = {
    // 1: {
    //     "name": "Ethereum Mainnet",
    //     "chainId": 1, 
    //     "symbol": "ETH",
    //     "decimals": 18,
    //     "rpcUrl":[ "https://ethereum-rpc.publicnode.com"],
    //     "blockExplorer": "https://etherscan.io",

    //     "uniswapV3": {
    //         "positionManager": "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
    //         "factory": "0x1F98431c8aD98523631AE4a59f267346ea31F984"
    //     },

    //     "fairLaunchFactory": "0x", 
    //     "feePool": 3000,
    //     "icon": "ETH.png",
    //     "blockSeconds": 13
    // }, 

    // 56: {
    //     "name": "BSC Mainnet",
    //     "chainId": 56,
    //     "symbol": "BNB",
    //     "decimals": 18,
    //     "rpcUrl": ["https://bsc-dataseed.binance.org"],
    //     "blockExplorer": "https://bscscan.com",

    //     "uniswapV3": {
    //         "positionManager": "0x46A15B0b27311cedF172AB29E4f4766fbE7F4364",
    //         "factory": "0x0BFbCF9fa4f9C56B0F40a671Ad40E0805A091865"
    //     },

    //     "fairLaunchFactory": "0x", 
    //     "feePool": 2500,
    //     "icon": "BNB.png",
    //     "blockSeconds": 3
    // },

    8453 : {
        "name": "Base",
        "chainId": 8453,
        "decimals": 18,
        "symbol": "BaseETH",
        "rpcUrl": ["https://mainnet.base.org"],
        "blockExplorer": "https://basescan.org",

        "uniswapV3" : {
            "positionManager": "0x03a520b32C04BF3bEEf7BEb72E919cf822Ed34f1",
            "factory": "0x33128a8fC17869897dcE68Ed026d694621f6FDfD"
        },
        "fairLaunchFactory": "0x2E34F5f0c591A4bdf6696dFd8964C9a0101631A1", 
        "feePool": 3000,
        "icon": "Base.png",
        "blockSeconds": 2
    },

    // 11155111: {
    //     "name": "Sepolia Testnet",
    //     "chainId": 11155111,
    //     "symbol": "testETH",
    //     "decimals": 18,
    //     "rpcUrl": ["https://ethereum-sepolia-rpc.publicnode.com"],
    //     "blockExplorer": "https://sepolia.etherscan.io",

    //     "uniswapV3": {
    //         "positionManager": "0x27F971cb582BF9E50F397e4d29a5C7A34f11faA2",
    //         "factory": "0x4752ba5DBc23f44D87826276BF6Fd6b1C372aD24"
    //     },

    //     "fairLaunchFactory": "0x", 
    //     "feePool": 3000,
    //     "icon": "Base.png",
    //     "blockSeconds": 13
    // },
    

    97 : {
        "name": "BSC Testnet",
        "chainId": 97,
        "symbol": "testBNB",
        "decimals": 18,
        "rpcUrl": ["https://data-seed-prebsc-1-s1.binance.org:8545"],
        "blockExplorer": "https://testnet.bscscan.com",

        "uniswapV3": {
            "positionManager": "0x427bF5b37357632377eCbEC9de3626C71A5396c1",
            "factory": "0x0BFbCF9fa4f9C56B0F40a671Ad40E0805A091865"
        },

        // "fairLaunchFactory": "0xB2103438aFd3D2c9fbE9c930bc9c8D66c1b70bBC", 
        "fairLaunchFactory": "0xc4A3A05072D3875bdf936552e7426633D5280c48",
        "feePool": 2500,
        "icon": "BNB.png",
        "blockSeconds": 3
    }

}