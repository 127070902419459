import { ethers } from 'ethers';

export const conn = async () => {
	let provider //, res, blocknumber
	try {
		provider = new ethers.BrowserProvider(window.ethereum)
		const network = await provider.getNetwork()
		const chainId = network.chainId
		return {
			provider,
			chainId
		}
	} catch (error) {
		console.error(error)
		throw error
	}
}

export const isInWeb3Browser = () => {
	return window.ethereum != null || window.web3 != null
}


// connect to the metamask or other web3 wallet with ethers.js
export const connect = async () => {
	let provider, res, blocknumber
	try {
		provider = new ethers.BrowserProvider(window.ethereum)
	} catch (error) {
		// Dialog.confirm({ message: 'Please install Metamask Or open In Metamask/TokenPocket APP' }).then(() => {
		// 	location.href = 'https://metamask.io/'
		// })
		console.error(error)
		throw error
	}
	try {
		res = await provider.send('eth_requestAccounts', [])
	} catch (error) {
		console.error(error)
		return;
	}
	try {
		blocknumber = await provider.getBlockNumber()
	} catch (error) {
		console.error(error)
		return;
	}

	const network = await provider.getNetwork()
	const chainId = network.chainId

	return {
		provider,
		blocknumber,
		chainId,
		address: res[0]
	};
}


export function onAccountChange(options) {
	// console.log(window.ethereum)
	window.ethereum.on('accountsChanged', async (accounts) => {
		options.success(accounts)
	})
}

export function onChainSwitch(options) {
	window.ethereum.on('chainChanged', async (chainId) => {
		options.success(chainId)
	});
}

/**
 * 
 * @param {
 *   chainId: CHAIN_ID,
 *   name: CHAIN_NAME,
 *   symbol: CHAIN_SYMBOL,
 *   decimals: 18,
 *   rpcUrls: [RPC],
 *   explorer: CHAIN_EXPLORER
 * } chain 
 * @param {*} callback 
 */
export async function switchNetwork(chain, callback) {

	// decimal to hex
	const targetChainId = '0x' + chain.chainId.toString(16)

	window.ethereum && window.ethereum.request({
		method: 'wallet_switchEthereumChain',
		params: [
			{
				chainId: targetChainId
			}
		],

	}).then(() => {
		console.log('Switch success')
		if (typeof callback === 'function') {
			callback();
		}
	}).catch(e => {

		if (!window.confirm('Error: ' + e.message + ',Do you want to add ' + chain.name + ' into your network configuration?')) {
			return;
		}

		window.ethereum && window.ethereum.request({
			method: 'wallet_addEthereumChain',
			params: [{
				chainId: targetChainId,
				chainName: chain.name,
				nativeCurrency: {
					name: chain.symbol,
					symbol: chain.symbol,
					decimals: chain.decimals
				},
				rpcUrls: chain.rpcUrls,
				blockExplorerUrls: [chain.explorer]
			}]
		}).then(() => {
			console.log('network add success')
			alert('Network add successful');
			if (typeof callback === 'function') {
				callback();
			}
		}).catch(e => {
			console.error(e)
			alert('ERROR:' + e.message);
		})

	})
}



export function watchTxRecp(timer, options) {
	if (timer) {
		clearInterval(timer)
	}
	timer = setInterval(() => {
		let new_options = { txid: options.txid, provider: options.provider }

		new_options.success = (recp) => {
			clearInterval(timer)

			if (typeof options.success === 'function') {
				options.success(recp)
			}
		}

		new_options.failed = (recp) => {
			clearInterval(timer)
			if (typeof options.failed === 'function') {
				options.failed(recp)
			}
		}

		new_options.process = () => {
			if (typeof options.process === 'function') {
				options.process()
			}
		}

		getTxReceipt(new_options)

	}, 800);
}

export function getTxReceipt(options) {
	options.provider.getTransactionReceipt(options.txid).then((recp) => {
		console.log("recp = ")
		console.log(recp)
		if (recp) {
			if (recp.status === 1) {
				if (typeof options.success === 'function') {
					options.success(recp);
				}
			} else if (recp.status === 0) {
				if (typeof options.failed === 'function') {
					options.failed(recp);
				}
			}
		} else {
			if (typeof options.process === 'function') {
				options.process()
			}
		}
	}).catch(e => {
		if (typeof options.error === 'function') {
			options.error(e);
		}
	})
}

// to EIP55
export function toEIP55(address) {
    if (!address) {
        return ''
    }
    console.log('address:', address)
    return ethers.getAddress(address)
}

export const tryConnectSilent = async () => {
	let provider, res, blocknumber
	try {
		provider = new ethers.BrowserProvider(window.ethereum)
	} catch (error) {
		console.error(error)
		throw error
	}
	try {
		res = await provider.listAccounts()
	} catch (error) {
		console.error(error)
		return null;
	}
	try {
		blocknumber = await provider.getBlockNumber()
	} catch (error) {
		console.error(error)
		return null;
	}

	const network = await provider.getNetwork()
	const chainId = network.chainId

	return {
		provider,
		blocknumber,
		chainId,
		address: res.length > 0 ? res[0].address : undefined
	};
}