const API_URL = 'https://upload.rocket.meme'

export const get = async (url) => {
    const response = await fetch(url);
    const data = await response.json();
    return data;
}

export const post = async (url, body) => {
    const response = await fetch(API_URL + url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    });
    const data = await response.json();
    return data;
}

export const uploadFile = async (url, fieldName, file) => {
    const formData = new FormData();
    formData.append(fieldName, file);
    const response = await fetch(API_URL + url, {
        method: 'POST',
        body: formData
    });
    const data = await response.text();
    return data;
}