import React from "react";

import CyberScopeImage from '../../assets/image/cyberscope.png';
import GitbookImage from '../../assets/image/gitbook.png';
import TelegramImage from '../../assets/image/telegram.png';
import XImage from '../../assets/image/x.png';


import "./Footer.css";

class Footer extends React.Component {
    render() {
        return (
            <footer className="footer">
                <div className="text-muted">
                    © rocket.meme 2024
                    <img src={CyberScopeImage} alt="cyberscope" style={{
                        width: '1rem',
                        height: '1rem',
                        marginLeft: '0.5rem',
                        marginRight: '0.5rem',
                    }} />
                    <a href="https://rocket.meme/" target="_blank" rel="noreferrer">Audited by Cyberscope. </a>
                </div>
                
                <div className="footerIcons">
                    <div onClick={() => {
                        document.location.href = 'https://rocketmeme.gitbook.io/';
                    }}>
                        {/* <SiGitbook style={{ fontSize: '1.2rem' }} />  */}
                        <img src={GitbookImage} alt="gitbook" />
                        </div>
                    <div onClick={() => {
                        document.location.href = 'https://x.com/rocketdotmeme';
                    }}>
                        {/* <BsTwitterX style={{ fontSize: '1.2rem' }} />  */}
                        <img src={XImage} alt="x" />
                        </div>
                    <div
                        onClick={() => {
                            document.location.href = 'https://t.me/rocketdotmeme';
                        }}
                    >
                        {/* <BsTelegram style={{ fontSize: '1.2rem' }} />  */}
                        <img src={TelegramImage} alt="telegram" />
                        </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
