import React from "react";
import {  Button } from "react-bootstrap";
import Logo from '../assets/logo.svg';

class HeaderDetail extends React.Component {


    render() {
        const {
            chain,
            address,
            onConnectClick
        } = this.props;

        return <div style={{
            display: "flex",
            padding: "1rem",
            backgroundColor: "#333",
            color: "#ffffff",

        }}>
            <div style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
                margin: "0 auto",
                width: "100%",
            }}>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                }}>
                    {/* logo */}
                    <img src={Logo} alt="logo" style={{
                        width: "2.2rem",
                        height: "2.2rem",
                        marginRight: "1rem",
                        cursor: "pointer",
                    }}
                        onClick={() => {
                            window.location.href = '/';
                        }}
                    ></img>
                    <div className="mobile-hide" style={{
                        marginRight: '0.2rem',
                        cursor: "pointer",
                    }} onClick={() => {
                        window.location.href = 'https://rocket.meme/';
                    }}>
                        <h3>Rocket</h3>
                    </div>
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "0.2rem 0.5rem",
                        borderRadius: "0.5rem",
                        backgroundColor: "#000",
                    }}>
                        {!chain ? <div>
                            Not Connected / Unsupported Chain
                        </div>
                            : <div style={{
                                display: "flex",
                                alignItems: "center",
                            }}>
                                {chain.icon && <img alt="chain_logo" src={require('../assets/logo/' + chain.icon)} style={{ width: "1rem", height: "1rem", marginRight: "0.5rem" }} ></img>}
                                {chain.name}
                            </div>}
                    </div>
                    <div style={{ width: "1rem" }}></div>
                    <div>
                        {/* address prefix 6 and suffix 6 */}
                        {address ? <div style={{
                            backgroundColor: "#1BB2DA",
                            color: "#ffffff",
                            padding: "0.2rem 0.5rem",
                            borderRadius: "0.5rem",
                        }}> {address.substring(0, 8) + '...' + address.substring(address.length - 6)} </div>: <div>
                            <Button variant="primary" onClick={onConnectClick}>Connect</Button>
                        </div>}
                    </div>
                </div>
            </div>
        </div>;
    }
}

function Header(props) {
    return <HeaderDetail chain={props.chain} address={props.address} onConnectClick={props.onConnectClick} />;
}

export default Header;